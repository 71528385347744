@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: 'Cairo', sans-serif;
}
textarea[id^='form-field_'] {
  text-align: left !important;
  direction: ltr !important;
}
[dir='rtl'] blockquote {
  border-right: 5px solid #ddd;
  border-left: none;
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
}

.svg-light-theme {
  filter: brightness(0) invert(1);
}

.svg-dark-theme {
  filter: brightness(0);
}

.datePicker {
  width: 100%;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@media (max-width: 640px) {
  .calendar-dialog {
    height: 85vh;
    overflow-y: auto;
  }

  .fc-toolbar-title {
    @apply hidden;
  }

  .fc-view-harness-active {
    height: 70vh !important;
  }
}

.fc-event {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.fc-event:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
